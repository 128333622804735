var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_payment_detail"),
        width: "50%",
        visible: _vm.visible
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.loading
        ? _c("a-skeleton", { attrs: { active: "" } })
        : [
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid":
                            "trucking-payment-detail-payment-number"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_payment_number")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.paymentNumber) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-account-name"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_account_name")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.driverAccountName) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-ujo-number"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_ujo_number")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.ujoNumber) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid":
                            "trucking-payment-detail-account-number"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_account_number")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.driverAccountNumber) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-payment-date"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_payment_date")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.paymentDate) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-amount"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_amount")) +
                            " : " +
                            _vm._s(_vm.formattedAmount) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-source-bank"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_source_bank")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.sourceBank) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-deposit"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_deposit")) +
                            " : " +
                            _vm._s(_vm.formattedDeposit) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-driver-name"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_driver_name")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.driverName) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "a-col",
                  { attrs: { span: 12, xs: 24, sm: 24, md: 24, lg: 12 } },
                  [
                    _c(
                      "p",
                      {
                        attrs: {
                          "data-testid": "trucking-payment-detail-deposit-notes"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_deposit_notes")) +
                            " : " +
                            _vm._s(_vm.paymentDetail.depositNotes) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 24 } }, [
                  _c(
                    "p",
                    {
                      attrs: {
                        "data-testid": "trucking-payment-detail-description"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lbl_description")) +
                          " : " +
                          _vm._s(_vm.paymentDetail.description) +
                          " "
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }